// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateEquipment",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipment/addnewequipment",
      payloadData.data
    )
    return response
  }
)

export const addUpdateDataFromExcel = createAsyncThunk(
  "api/AddUpdateEquipmentFromExcel",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipment/AddUpdateEquipmentFromExcel",
      payloadData
    )
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddEquipment",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipment/addnewequipment",
      payloadData.data
    )
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/equipment/view",
  async (params) => {
    const response = await axios.get("/api/equipment/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For updating equipment picklist's options if location were to change
export const getDataByLocationId = createAsyncThunk(
  "api/equipment/view/filter",
  async (params) => {
    const response = await axios.get("/api/equipment/view", params)
    let filteredData = response.data
    if (params !== undefined) {
      filteredData = filteredData.filter((item) => {
        return Object.values(item)[6] === params
      })
    }
    return {
      params,
      data: filteredData,
      allData: filteredData,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/equipment/fetch",
  async (params) => {
    const response = await axios.get(
      `/api/equipment/fetchwithlocation/${params}`
    )
    return {
      data: response.data
    }
  }
)

export const DownloadOfflineConfigTemplate = createAsyncThunk(
  "api/equipment/DownloadOfflineConfigTemplate",
  async (params) => {
    console.log(params)
    const response = await axios
      .get(`/api/equipment/DownloadOfflineConfigTemplate`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const blobObject = new Blob([response.data])
        const responseHeaders = response.headers

        const contentDisposition = responseHeaders["content-disposition"]

        let fileName // this will get u both the file name and the extension
        if (
          contentDisposition &&
          contentDisposition.indexOf("attachment") !== -1
        ) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "")
          }
        }

        const url = window.URL.createObjectURL(blobObject)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => console.log(error))
    return response
  }
)

export const appEquipmentSlice = createSlice({
  name: "appEquipment",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0,
    uploaded: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
      state.uploaded = 0
    },
    // this is needed for the scenario where u change from a level of 1 asset to another with also 1 asset
    // since the length of allData nvr change, there is a need to reset the allData
    [getDataByLocationId.pending]: (state) => {
      state.dtoData = {}
      state.allData = []
      state.updated = 0
      state.uploaded = 0
    },
    [getDataByLocationId.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
      state.uploaded = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
      state.uploaded = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
      state.uploaded = 0
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
      state.uploaded = 0
    },
    // for use with offlineconfig
    [addUpdateDataFromExcel.fulfilled]: (state) => {
      state.uploaded = 1
    }
  }
})

export default appEquipmentSlice.reducer
