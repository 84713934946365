// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updatestoresetup",
  async (payloadData) => {
    const response = await axios.post("/api/storesetup", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk(
  "api/addstoresetup",
  async (payloadData) => {
    const response = await axios.post("/api/storesetup", payloadData.data)
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/storesetup/view",
  async (params) => {
    const response = await axios.get("/api/storesetup/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/storesetup/fetch",
  async (params) => {
    const response = await axios.get(`/api/storesetup/${params}`)
    return {
      data: response.data
    }
  }
)

export const appStoresetupSlice = createSlice({
  name: "appStoresetup",
  initialState: {
    allData: [
      {
        Id: 1,
        storename: "store1",
        location: "RMMS",
        active: "Yes"
      },
      {
        Id: 2,
        storename: "store2",
        location: "RMMS",
        active: "Yes"
      },
      {
        Id: 3,
        storename: "store3",
        location: "RMMS",
        active: "Yes"
      }
    ],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      if (payload.allData !== undefined && payload.allData.length > 0) {
        state.dtoData = {}
        state.allData = payload.allData
        state.updated = 0
      }
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appStoresetupSlice.reducer
