// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateschedule",
  async (payloadData) => {
    const response = await axios.post("/api/schedule", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk("api/schedule", async (payloadData) => {
  const response = await axios.post("/api/schedule", payloadData.data)
  return response
})

export const AddNewSchedule = createAsyncThunk(
  "api/Schedule/AddNewSchedule",
  async (payloadData) => {
    const response = await axios.post(
      "/api/Schedule/AddNewSchedule",
      payloadData.data
    )

    return {
      data: response.data
    }
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/equipmenttaskandschedule/view",
  async (params) => {
    const response = await axios.get(
      "/api/equipmenttaskandschedule/view",
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/schedule/fetch",
  async (params) => {
    const response = await axios.get(`/api/schedule/${params}`)
    return {
      data: response.data
    }
  }
)

export const appEquipmenttaskandscheduleSlice = createSlice({
  name: "appEquipmenttaskandschedule",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [AddNewSchedule.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appEquipmenttaskandscheduleSlice.reducer
