// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getData = createAsyncThunk(
  "api/chirpstack/device/list",
  async (params) => {
    const response = await axios.get(`/api/chirpstack/device?appId=${params}`)
    return {
      params,
      allData: response.data.result
    }
  }
)

export const appIaqSlice = createSlice({
  name: "appIaq",
  initialState: {
    allData: [],
    dtoData: {}
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    }
  }
})

export default appIaqSlice.reducer
