// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getData = createAsyncThunk(
  "api/scheduletask/view",
  async (params) => {
    const response = await axios.get("/api/scheduletask/view", params)
    let filteredData = response.data
    filteredData = filteredData.filter((item) => {
      return Object.values(item)[3] === params
    })
    return {
      allData: filteredData
    }
  }
)

export const addAndUpdateScheduleTasks = createAsyncThunk(
  "api/equipmenttaskandschedule/addupdate",
  async (payloadData) => {
    const response = await axios.post(
      "/api/scheduletask/addandupdatescheduletasks",
      payloadData.data
    )
    return response
  }
)

// For Populating of task list in EquipmentTaskAndSchedule
export const getCorrespondingScheduleTasksBasedOnScheduleId = createAsyncThunk(
  "api/equipmenttaskandschedule/fetch",
  async (params) => {
    const response = await axios.get(
      `/api/scheduletask/getcorrespondingscheduletasksbasedonscheduleid/${params}`
    )
    return {
      data: response.data
    }
  }
)

export const appScheduleTaskSlice = createSlice({
  name: "appScheduleTask",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = state.dtoData
      state.allData = payload.allData
      state.updated = 0
    },
    [getCorrespondingScheduleTasksBasedOnScheduleId.fulfilled]: (
      state,
      { payload }
    ) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addAndUpdateScheduleTasks.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appScheduleTaskSlice.reducer
