// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/AddServiceRequest",
  async (payloadData) => {
    const response = await axios.post("/api/workorder", payloadData.data)
    return response
  }
)

// Update data without the need for backend to reassign to someone else
// Called when there is a status change or an order got redirected
export const updateDataWithoutBackendReassigning = createAsyncThunk(
  "api/AddServiceRequest",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/updatewowithoutbackendreassigning",
      payloadData.data
    )
    return {
      payloadData,
      data: response.data
    }
  }
)

export const addData = createAsyncThunk(
  "api/AddServiceRequest",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/addnewwo",
      payloadData.data
    )
    return {
      payloadData,
      data: response.data
    }
  }
)

export const addDataWithDoc = createAsyncThunk(
  "api/AddServiceRequestWithDoc",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/addnewwowithdoc",
      payloadData.data
    )
    return response
  }
)

// For workorder's edit, fetches signature as well if have
export const fetchSingleRecordWithSignature = createAsyncThunk(
  "api/ServiceRequest/fetch",
  async (params) => {
    let response
    if (params.Id) {
      response = await axios.get(
        `/api/workorder/fetchwithsignature/${params.Id}`
      )
    } else {
      response = await axios.get(`/api/workorder/fetchwithsignature/${params}`)
    }

    return {
      data: response.data,
      temp: params.bool
    }
  }
)

const reformatDateTime = (dateTime) => {
  const tempArray = dateTime.toString().split("T")
  const firstHalfArray = tempArray[0].split("-")
  const firstHalf = `${firstHalfArray[2]}-${firstHalfArray[1]}-${firstHalfArray[0]}`

  let secondHalf = tempArray[1].split(".")[0]
  const secondHalfArray = secondHalf.split(":")
  let hours = parseInt(secondHalfArray[0])
  if (hours >= 12) secondHalfArray.push("pm")
  else secondHalfArray.push("am")
  if (hours > 12) hours -= 12

  if (hours < 10) secondHalfArray[0] = `0${hours.toString()}`
  else secondHalfArray[0] = `${hours.toString()}`

  secondHalf = `${secondHalfArray[0]}:${secondHalfArray[1]}:${secondHalfArray[2]} ${secondHalfArray[3]}`
  const newDateTime = firstHalf.concat(" ", secondHalf)
  return newDateTime
}

const cleanUpData = (value) => {
  value["Date"] = reformatDateTime(value["Date"])
}

// For Listing
export const getData = createAsyncThunk(
  "api/servicerequest/view",
  async (params) => {
    const response = await axios.get("/api/servicerequest/view", params)
    response.data.forEach(cleanUpData)
    const response2 = await axios.get("/api/workorderstatus/view", params)
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    return {
      params,
      data: response.data,
      allData: response.data,
      statusData: response2.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/servicerequest/fetch",
  async (params) => {
    const response = await axios.get(`/api/servicerequest/${params}`)
    return {
      data: response.data
    }
  }
)

export const appServicerequestSlice = createSlice({
  name: "appServicerequest",
  initialState: {
    allData: [],
    dtoData: {},
    ftoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.ftoData = {}
      state.allData = payload.allData
      state.statusData = payload.statusData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = payload.data
      state.statusData = state.statusData
      state.updated = 0
    },
    [fetchSingleRecordWithSignature.fulfilled]: (state, { payload }) => {
      state.ftoData = {}
      state.dtoData = payload.data
      state.statusData = state.statusData
      state.updated = 0
    },
    [addData.fulfilled]: (state, { payload }) => {
      state.ftoData = payload.data
      state.updated = 1
    },
    [addDataWithDoc.fulfilled]: (state, { payload }) => {
      state.ftoData = payload.data
      state.updated = 1
    },
    [updateData.fulfilled]: (state, { payload }) => {
      state.ftoData = payload.data
      state.updated = 1
    },
    [updateDataWithoutBackendReassigning.fulfilled]: (state, { payload }) => {
      state.ftoData = payload.data
      state.updated = 1
    }
  }
})

export default appServicerequestSlice.reducer
