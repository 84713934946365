// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateUser",
  async (payloadData) => {
    const response = await axios.post("/api/user", payloadData.data)
    return response
  }
)
export const UpdateUser = createAsyncThunk(
  "api/updateUser",
  async (payloadData) => {
    const response = await axios.post("/api/user/UpdateUser", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk("api/AddUser", async (payloadData) => {
  const response = await axios.post("/api/user", payloadData.data)
  return response
})
export const addNewDataWOPassword = createAsyncThunk(
  "api/AddUser",
  async (payloadData) => {
    const response = await axios.post("/api/user/AddNewUser", payloadData.data)
    return response
  }
)
export const addGuestData = createAsyncThunk(
  "api/AddUser",
  async (payloadData) => {
    const response = await axios.post(
      "/api/user/AddGuestUser",
      payloadData.data
    )
    return {
      data: response.data
    }
  }
)

// For Listing
export const getData = createAsyncThunk("api/user/view", async () => {
  const response = await axios.get("/api/user/view")
  return {
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})

// For selectable picklist
export const getActiveUsers = createAsyncThunk(
  "api/user/view",
  async (params) => {
    const response = await axios.get("/api/user/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For selectable picklist
export const getActiveUsersWithNoOne = createAsyncThunk(
  "api/user/view",
  async (params) => {
    const response = await axios.get("/api/user/view", params)
    response.data.unshift({
      Id: 0,
      Name: "",
      PhoneNumber: ""
    })
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/user/fetch",
  async (params) => {
    const response = await axios.get(`/api/user/${params}`)
    return {
      data: response.data
    }
  }
)
export const fetchAllRecord = createAsyncThunk("api/user", async () => {
  const response = await axios.get(`/api/user`)
  return {
    allData: response.data
  }
})

export const appUserSlice = createSlice({
  name: "appUser",
  initialState: {
    allData: [],
    allData2: [],
    dtoData: {},
    newUserData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchAllRecord.fulfilled]: (state, { payload }) => {
      state.allData2 = payload.allData
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    },
    [addGuestData.fulfilled]: (state, { payload }) => {
      state.allData = state.allData
      state.newUserData = payload.data
    }
  }
})

export default appUserSlice.reducer
