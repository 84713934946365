// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateTeamsToSystemType",
  async (payloadData) => {
    const response = await axios.post(
      "/api/teamstosystemtype",
      payloadData.data
    )
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddTeamsToSystemType",
  async (payloadData) => {
    const response = await axios.post(
      "/api/teamstosystemtype",
      payloadData.data
    )
    return response
  }
)

export const getWholeData = createAsyncThunk(
  "api/teamstosystemtype",
  async (params) => {
    const response = await axios.get("/api/teamstosystemtype", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

//// For Listing (cy - this will not work as there's no view for now)
//export const getData = createAsyncThunk('api/workorder/view', async params => {
//    const response = await axios.get('/api/workorder/view', params)
//    return {
//        params,
//        data: response.data,
//        allData: response.data,
//        totalPages: 1
//    }
//})

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/teamstosystemtype/fetch",
  async (params) => {
    const response = await axios.get(`/api/teamstosystemtype/${params}`)
    return {
      data: response.data
    }
  }
)

export const appTeamsToSystemTypeSlice = createSlice({
  name: "appTeamsToSystemType",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getWholeData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appTeamsToSystemTypeSlice.reducer
