// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// backend will reassign
export const updateData = createAsyncThunk(
  "api/AddWorkorder",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/addnewwo",
      payloadData.data
    )
    return response
  }
)

// Update data without the need for backend to reassign to someone else
// Called when there is a status change or an order got redirected
export const updateDataWithoutBackendReassigning = createAsyncThunk(
  "api/AddWorkorder",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/updatewowithoutbackendreassigning",
      payloadData.data
    )
    return response
  }
)

export const updateDataWithoutBackendReassigningWithSignature =
  createAsyncThunk("api/AddWorkorder", async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/updatewowithsignature",
      payloadData.data
    )
    return response
  })

export const addData = createAsyncThunk(
  "api/AddWorkorder",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/addnewwo",
      payloadData.data
    )
    return response
  }
)

export const addDataWithDoc = createAsyncThunk(
  "api/AddWorkorderWithDoc",
  async (payloadData) => {
    const response = await axios.post(
      "/api/workorder/addnewwowithdoc",
      payloadData.data
    )
    return response
  }
)

const reformatDateTime = (dateTime) => {
  const tempArray = dateTime.toString().split("T")
  const firstHalfArray = tempArray[0].split("-")
  const firstHalf = `${firstHalfArray[2]}-${firstHalfArray[1]}-${firstHalfArray[0]}`

  let secondHalf = tempArray[1].split(".")[0]
  const secondHalfArray = secondHalf.split(":")
  let hours = parseInt(secondHalfArray[0])
  if (hours >= 12) secondHalfArray.push("pm")
  else secondHalfArray.push("am")
  if (hours > 12) hours -= 12

  if (hours < 10) secondHalfArray[0] = `0${hours.toString()}`
  else secondHalfArray[0] = `${hours.toString()}`

  secondHalf = `${secondHalfArray[0]}:${secondHalfArray[1]}:${secondHalfArray[2]} ${secondHalfArray[3]}`
  const newDateTime = firstHalf.concat(" ", secondHalf)
  return newDateTime
}

const cleanUpData = (value) => {
  value["Date"] = reformatDateTime(value["Date"])
}

// For Listing
export const getData = createAsyncThunk(
  "api/servicereport/view",
  async (params) => {
    const response = await axios.get("/api/servicereport/view", params)
    response.data.forEach(cleanUpData)
    const response2 = await axios.get("/api/workorderstatus/view", params)
    response2.data = response2.data.sort((a, b) => a.value - b.value)
    return {
      params,
      data: response.data,
      allData: response.data,
      statusData: response2.data,
      totalPages: 1
    }
  }
)

const cleanUpPastData = (value) => {
  value["Date"] = reformatDateTime(value["Date"])
  if (value["Resolved_On"]) {
    value["Resolved_On"] = reformatDateTime(value["Resolved_On"])
  }
}

// For Past Listing
export const getPastData = createAsyncThunk(
  "api/pastworkorder/view",
  async (params) => {
    const response = await axios.get("/api/pastworkorder/view", params)
    response.data.forEach(cleanUpPastData)
    return {
      pastWorkOrder: response.data
    }
  }
)

// For service request's Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/workorder/fetch",
  async (params) => {
    const response = await axios.get(`/api/workorder/fetch/${params}`)
    return {
      data: response.data
    }
  }
)

// For workorder's edit, fetches signature as well if have
export const fetchSingleRecordWithSignature = createAsyncThunk(
  "api/workorder/fetch",
  async (params) => {
    let response
    if (params.Id) {
      response = await axios.get(
        `/api/workorder/fetchwithsignature/${params.Id}`
      )
    } else {
      response = await axios.get(`/api/workorder/fetchwithsignature/${params}`)
    }
    return {
      data: response.data,
      temp: params.bool
    }
  }
)

// For downloading workorder reports
export const downloadWorkOrderReport = createAsyncThunk(
  "api/workorder/DownloadWoReport",
  async (params) => {
    const response = await axios
      .get(`/api/workorder/downloadworeport/${params}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const blobObject = new Blob([response.data])
        const responseHeaders = response.headers

        const contentDisposition = responseHeaders["content-disposition"]

        let fileName // this will get u both the file name and the extension
        if (
          contentDisposition &&
          contentDisposition.indexOf("attachment") !== -1
        ) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "")
          }
        }

        const url = window.URL.createObjectURL(blobObject)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => console.log(error))
    return response
  }
)

export const appWorkorderSlice = createSlice({
  name: "appServiceReport",
  initialState: {
    allData: [],
    dtoData: {},
    ttoData: {},
    pastWorkOrder: [],
    statusData: [],
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.statusData = payload.statusData
      state.updated = 0
    },
    [getPastData.fulfilled]: (state, { payload }) => {
      state.dtoData = state.dtoData
      state.pastWorkOrder = payload.pastWorkOrder
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      if (payload.temp && payload.temp === true) {
        state.dtoData = state.dtoData
        state.ttoData = payload.data
      } else {
        state.dtoData = payload.data
        state.ttoData = state.ttoData
      }
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [addDataWithDoc.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appWorkorderSlice.reducer
