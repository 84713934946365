import { createSlice } from "@reduxjs/toolkit"

export const coreAppSlice = createSlice({
  name: "coreStore",
  initialState: {
    nav: []
  },
  reducers: {
    setNav(state, action) {
      state.nav = action.payload
    }
  }
})

export const { setNav } = coreAppSlice.actions
export default coreAppSlice.reducer
