// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateTask",
  async (payloadData) => {
    const response = await axios.post("/api/task", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk("api/AddTask", async (payloadData) => {
  const response = await axios.post("/api/task", payloadData.data)
  return response
})

// For Listing
export const getData = createAsyncThunk("api/task/view", async (params) => {
  const response = await axios.get("/api/task/view", params)
  return {
    params,
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})

// For updating task picklist's options if asset's maintype would to change
export const getDataByAssetMainTypeId = createAsyncThunk(
  "api/task/view/filter",
  async (params) => {
    const response = await axios.get("/api/task/view", params)
    let filteredData = response.data
    filteredData = filteredData.filter((item) => {
      return Object.values(item)[4] === params
    })
    return {
      allData: filteredData
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/task/fetch",
  async (params) => {
    const response = await axios.get(`/api/task/${params}`)
    return {
      data: response.data
    }
  }
)
// For Delete
export const deleteSingleRecord = createAsyncThunk(
  "api/task/delete",
  async (params) => {
    const response = await axios.delete(`/api/task/${params}`)
    return response
  }
)

export const appTaskSlice = createSlice({
  name: "appTask",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    // this is needed for the scenario where u change from an asset of 1 maintype to another with also 1 maintype
    // since the length of allData nvr change, there is a need to reset the allData
    [getDataByAssetMainTypeId.pending]: (state) => {
      state.dtoData = {}
      state.allData = []
      state.updated = 0
    },
    [getDataByAssetMainTypeId.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    },
    [deleteSingleRecord.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appTaskSlice.reducer
