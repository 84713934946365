// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getData = createAsyncThunk(
  "api/datasourcemaster/view",
  async (params) => {
    const response = await axios.get("/api/datasourcemaster/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

export const appDataSourceSlice = createSlice({
  name: "appDataSource",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    }
  }
})

export default appDataSourceSlice.reducer
