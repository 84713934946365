// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getData = createAsyncThunk(
  "api/reportassetdowntime/GetReportAssetDowntimeInfo",
  async (params) => {
    const response = await axios.get(
      `/api/reportassetdowntime/GetReportAssetDowntimeInfo/${params[0]}/${params[1]}`,
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

export const appEquipmentDowntimeSlice = createSlice({
  name: "appequipmentdowntime",
  initialState: {
    allData: []
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.allData = payload.allData
    }
  }
})

export default appEquipmentDowntimeSlice.reducer
