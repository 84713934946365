// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateINV_MaterialRequestTrans",
  async (payloadData) => {
    const response = await axios.post(
      "/api/INV_MaterialRequestTrans",
      payloadData.data
    )
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddINV_MaterialRequestTrans",
  async (payloadData) => {
    const response = await axios.post(
      "/api/INV_MaterialRequestTrans",
      payloadData.data
    )
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/INV_MaterialRequestTrans/view",
  async (params) => {
    const response = await axios.get(
      "/api/INV_MaterialRequestTrans/view",
      params
    )
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/INV_MaterialRequestTrans/fetch",
  async (params) => {
    const response = await axios.get(
      `/api/INV_MaterialRequestTrans/FetchCustomSingleRecord/${params}`
    )
    return {
      data: response.data
    }
  }
)

export const appINV_MaterialRequestTransSlice = createSlice({
  name: "appINV_MaterialRequestTrans",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = []
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appINV_MaterialRequestTransSlice.reducer
