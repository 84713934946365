// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// For Listing
export const getData = createAsyncThunk(
  "api/facilitytreehieracy/GetTreeList",
  async (params) => {
    const response = await axios.get(
      "/api/facilitytreehieracy/GetTreeList",
      params
    )
    return {
      params,
      allData: response.data,
      totalPages: 1
    }
  }
)

export const apptreehierachySlice = createSlice({
  name: "apptreehierachy",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    }
  }
})

export default apptreehierachySlice.reducer
