// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateequipmentschedule",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipmentschedule",
      payloadData.data
    )
    return response
  }
)

//export const addData = createAsyncThunk('api/equipmentschedule', async payloadData => {
//    const response = await axios.post('/api/equipmentschedule', payloadData.data)
//    return response
//})

// For Edit
export const getAttachedScheduleToEquipment = createAsyncThunk(
  "api/equipmentschedule/getattachedscheduletoequipment",
  async (params) => {
    const response = await axios.get(
      `/api/equipmentschedule/getattachedscheduletoequipment/${params}`
    )
    return {
      data: response.data
    }
  }
)

export const appEquipmentScheduleSlice = createSlice({
  name: "appEquipmentSchedule",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getAttachedScheduleToEquipment.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    //[addData.fulfilled]: (state) => {
    //    state.updated = 1
    //},
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appEquipmentScheduleSlice.reducer
