// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const addData = createAsyncThunk(
  "api/AddPpmSchedule",
  async (payloadData) => {
    const response = await axios.post("/api/ppmschedule", payloadData.data)
    return response
  }
)

// To make events appear on calendar
export const fetchEvents = createAsyncThunk(
  "api/ppmschedule/view",
  async (params) => {
    const response = await axios.get("/api/ppmschedule/view", params)
    return response.data
  }
)
export const fetchEventsWithFilter = createAsyncThunk(
  "api/ppmschedule/view",
  async (params) => {
    const response = await axios.get("/api/ppmschedule/view", params)
    if (response && response.data && response.data.length > 0) {
      response.data = Object.values(response.data).filter((value) => {
        return params.some(
          (y) => y.label === value.extendedProps && y.ticked === true
        )
      })
    }
    return response.data
  }
)

export const fetchSingleRecord = createAsyncThunk(
  "api/ppmschedule/fetch",
  async (params) => {
    const response = await axios.get(`/api/ppmschedule/${params}`)
    return {
      data: response.data
    }
  }
)

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    dtoData: {},
    selectedEvent: {},
    selectedCalendars: ["Personal", "Business", "Family", "Holiday", "ETC"]
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: {
    [fetchEvents.fulfilled]: (state, action) => {
      state.dtoData = []
      state.events = action.payload
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    }
    //.addCase(updateFilter.fulfilled, (state, action) => {
    //  if (state.selectedCalendars.includes(action.payload)) {
    //    state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
    //  } else {
    //    state.selectedCalendars.push(action.payload)
    //  }
    //})
    //.addCase(updateAllFilters.fulfilled, (state, action) => {
    //  const value = action.payload
    //  let selected = []
    //  if (value === true) {
    //    selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
    //  } else {
    //    selected = []
    //  }
    //  state.selectedCalendars = selected
    //})
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
