// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateEquipmentDocument",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipmentdocument/AddNewEqDoc",
      payloadData.data
    )
    return response
  }
)

export const addData = createAsyncThunk(
  "api/AddEquipmentDocument",
  async (payloadData) => {
    const response = await axios.post(
      "/api/equipmentdocument/AddNewEqDoc",
      payloadData.data
    )
    return response
  }
)

// For Listing
export const getData = createAsyncThunk(
  "api/equipmentdocument/view",
  async (params) => {
    const response = await axios.get("/api/equipmentdocument/view", params)
    return {
      params,
      data: response.data,
      allData: response.data,
      totalPages: 1
    }
  }
)

// For downloading uploaded docs
export const downloadEquipmentDocs = createAsyncThunk(
  "api/downloadEqDoc",
  async (params) => {
    const response = await axios
      .get(`/api/equipmentdocument/downloadeqdoc/${params}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const blobObject = new Blob([response.data])
        const responseHeaders = response.headers

        const contentDisposition = responseHeaders["content-disposition"]

        let fileName // this will get u both the file name and the extension
        if (
          contentDisposition &&
          contentDisposition.indexOf("attachment") !== -1
        ) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "")
          }
        }

        const url = window.URL.createObjectURL(blobObject)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => console.log(error))
    return response
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/equipmentdocument/fetch",
  async (params) => {
    const response = await axios.get(`/api/equipmentdocument/${params}`)
    return {
      data: response.data
    }
  }
)

export const appEquipmentDocumentSlice = createSlice({
  name: "appEquipmentDocument",
  initialState: {
    allData: [],
    dtoData: {},
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appEquipmentDocumentSlice.reducer
